import React, { useState, useContext } from 'react';
import PageContext from 'contexts/page';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import { useDataLayer, useDataLayerBuilder } from '@tymate/react-gtm';
import HeaderSection from 'components/Hotel/header-section';
import { graphql } from 'gatsby';
import OurPromiseSection from 'components/Hotel/our-promise-section';
import ServicesSection from 'components/Hotel/services-section';
import EngagementsSection from 'components/Hotel/engagements-section';
import AccessSection from 'components/Hotel/access-section';
import BaseSection from 'components/Hotel/base-section';
import Navbar from 'components/Hotel/partials/navbar';
import RestaurantsSection from 'components/Hotel/restaurants-section';
import { useAsync } from 'react-async';
import { getHotel } from 'api/hotels';
import ReviewsSection from 'components/Hotel/reviews-section';
import LabelsSection from 'components/Hotel/labels-section';
import styled from 'styled-components';
import { media, SpinnerContainer } from 'ui';
import Spinner from 'components/Spinner';

const MarginContainer = styled.div`
  ${media.desktop`
        margin-left:9vw;
      `}
`;

export default function Hotel({ data: { hotel } }) {
  useDataLayerBuilder(({ hotel }) => ({
    pageType: 'hotel',
    searchHotel: hotel.displayName,
    searchHotelId: hotel.originalId,
    hotelStars: hotel?.stars,
  }));

  useDataLayer({
    ecommerce: {
      detail: {
        actionField: { list: 'Search' },
        products: [
          {
            id: hotel.originalId,
            name: hotel.displayName,
            brand: 'Feelingo',
            category: 'Hotel',
          },
        ],
      },
    },
  });

  const { language } = useContext(PageContext);

  const { data, isLoading } = useAsync({
    promiseFn: getHotel,
    id: hotel.originalId,
    language,
  });

  const [sectionSelected, setSectionSelected] = useState('');

  const valueRootMargin = '-5% 0px -95%';
  return (
    <Layout className="tw" footerVariant="home" noSticky>
      <SEO title={hotel.displayName} />
      <Navbar sectionSelected={sectionSelected} />

      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          <BaseSection
            sectionId={'description'}
            setSectionSelected={setSectionSelected}
            threshold={0}
            rootMargin={valueRootMargin}
          >
            <HeaderSection hotel={hotel} />
          </BaseSection>

          <div className="bg-white">
            <MarginContainer className="max-w-xl xl:max-w-3xl mx-auto">
              <BaseSection
                sectionId={'promise'}
                setSectionSelected={setSectionSelected}
                threshold={0}
                rootMargin={valueRootMargin}
              >
                <OurPromiseSection hotelApi={data} />
              </BaseSection>

              <BaseSection
                sectionId={'engagements'}
                setSectionSelected={setSectionSelected}
                threshold={0}
                rootMargin={valueRootMargin}
              >
                <EngagementsSection hotel={hotel} />
              </BaseSection>

              <BaseSection
                sectionId={'services'}
                setSectionSelected={setSectionSelected}
                threshold={0}
                rootMargin={valueRootMargin}
              >
                <ServicesSection hotel={hotel} />
              </BaseSection>

              <BaseSection
                sectionId={'labels'}
                setSectionSelected={setSectionSelected}
                threshold={0}
                rootMargin={valueRootMargin}
              >
                <LabelsSection hotel={hotel} />
              </BaseSection>

              <BaseSection
                sectionId={'restaurants'}
                setSectionSelected={setSectionSelected}
                threshold={0}
                rootMargin={valueRootMargin}
              >
                <RestaurantsSection hotel={hotel} />
              </BaseSection>
            </MarginContainer>
          </div>

          <div className="bg-brand-grey">
            <MarginContainer className="max-w-xl xl:max-w-3xl mx-auto">
              <BaseSection
                sectionId={'access'}
                setSectionSelected={setSectionSelected}
                threshold={0}
                rootMargin={valueRootMargin}
              >
                <AccessSection hotel={hotel} />
              </BaseSection>
            </MarginContainer>
          </div>

          <div className="bg-white">
            <MarginContainer className="max-w-xl xl:max-w-3xl mx-auto">
              <BaseSection
                sectionId={'reviews'}
                setSectionSelected={setSectionSelected}
                threshold={0}
                rootMargin={valueRootMargin}
              >
                <ReviewsSection isList hotel={hotel} />
              </BaseSection>
            </MarginContainer>
          </div>
        </>
      )}
      <div className="h-px w-full bg-gray-300" />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($language: String!, $id: String!) {
    hotel(language: { eq: $language }, id: { eq: $id }) {
      id
      originalId
      koediaId
      address
      displayName
      phoneNumber
      language
      roomCount
      lat
      lon
      stars
      locations {
        displayName
      }
      city {
        title
        slug
        lat
        lon
        country {
          title
          slug
        }
      }
      officialCategories {
        displayName
      }
      customCategories {
        displayName
      }
      facilities {
        id
        displayName
        kind
        icon {
          url
        }
      }

      hotelFacilities {
        details
        facility {
          id
          displayName
          position
          icon {
            url
          }
          group {
            displayName
            id
            position
          }
        }
      }

      sustainable {
        level
        accreditationSustainablesCount
        accreditations {
          id
          displayName
          content
          pictogram {
            url
          }
        }
      }
      secondaryAttachments {
        id
        displayName
        mediumUrl
        largeUrl
        smallUrl
      }
      primaryAttachment {
        id
        displayName
        mediumUrl
        largeUrl
        smallUrl
        iconUrl
      }
      types {
        displayName
      }
      styles {
        displayName
      }
      sustainableEngagements {
        displayName
        numeroLigne
        icon {
          url
        }
      }
      restaurants {
        displayName
        capacity
        description
        facilities {
          id
          displayName
          kind
          icon {
            url
          }
          group {
            displayName
            id
          }
        }
        attachments {
          id
          displayName
          mediumUrl
          largeUrl
          smallUrl
          iconUrl
        }
      }
      meetingRooms {
        displayName
        capacity
        description
        facilities {
          id
          displayName
          kind
          icon {
            url
          }
          group {
            displayName
            id
          }
        }
        attachments {
          id
          displayName
          mediumUrl
          largeUrl
          smallUrl
          iconUrl
        }
      }
      hotelDescriptions {
        content
        description {
          displayName
        }
      }
      hotelSustainableClusters {
        descriptions
        sustainableCluster {
          displayName
          sort
        }
      }
      hotelRooms {
        count
        description
        attachments {
          id
          displayName
          mediumUrl
          largeUrl
          smallUrl
          iconUrl
        }
        facilities {
          id
          displayName
        }
        room {
          displayName
        }
      }
    }
  }
`;
