import React from 'react';
import { useTranslation } from 'react-i18next';
import ServicesHotel from '../../components/ServicesHotel';

export default function ServicesSection({hotel}) {
  const {t} = useTranslation();
  return (
    <div className="py-16 px-8 sm:px-0 mx-8 xl:mx-0">
      <span className="text-2xl font-bold text-brand-black">{t('hotel.overview.services.title')}</span>

      <ServicesHotel {...hotel} />
    </div>
  )
}
