import React from 'react';
import star from '../assets/svg/star.svg';

export default function HotelStars({ stars }) {
  return stars > 0 && (
    <div className="tw bg-brand-altyellow py-2 px-4 rounded-3xl">
      <div className="flex space-x-1">
        {[...Array(stars)].map((v) => (
          <img className="h-4 w-4" src={star} alt="Star icon"/>
        ))}
      </div>
    </div>
  )
}
