import React, { useState } from 'react';
import find from 'lodash/find';
import HotelLeaves from '../../components/HotelLeaves';
import HotelStars from '../../components/HotelStars';
import PhotoCarrousel from './partials/photo-carrousel';
import Prices from './partials/prices';
import styled from 'styled-components';
import {media} from '../../ui/index';

const MarginContainer = styled.div`
  ${media.desktop`
    margin-left:9vw;
  `}
`;

const PricesContainer = styled.div`
  position:relative;
`;

export default function HeaderSection(props) {

  const images = props.hotel?.secondaryAttachments.map(
    ({ largeUrl, displayName }) => {
      return { src: largeUrl, caption: displayName };
    },
  );
  if (props.hotel?.primaryAttachment?.largeUrl) {
    images.unshift({ src: props.hotel?.primaryAttachment?.largeUrl, caption: props.hotel?.primaryAttachment?.displayName })
  }

  const hotelDescription = find(
    props.hotel.hotelDescriptions,
    ({ description }) => description.displayName === 'Description',
  );

  const [showStickyWidget, setShowStickyWidget] = useState(false);

  return (
    <>
      <div className="tw bg-brand-grey py-10">
        <div>
          <PhotoCarrousel images={images}/>
        </div>
        <PricesContainer>
          <Prices hotel={props.hotel} setShowStickyWidget={setShowStickyWidget} sticky={showStickyWidget}/>
        </PricesContainer>

        <MarginContainer className="mt-10 max-w-6xl mx-8 xl:mx-0">
          <div>
            <div className="flex flex-col lg:flex-row items-center gap-3">
              <span className="max-w-sm text-center lg:text-left text-4xl text-brand-black font-bold">{props.hotel.displayName}</span>
              {props.hotel.sustainable && <HotelLeaves level={props.hotel.sustainable.level}/>}
              <HotelStars stars={props.hotel.stars}/>
            </div>
            {  hotelDescription?.content && <div
              className="mt-5 max-w-2xl text-brand-text font-semibold mb-0"
              dangerouslySetInnerHTML={{ __html: hotelDescription?.content }}
            />
            }

          </div>

          <div className="mt-8 max-w-5xl flex items-center flex-wrap">
            {(props.hotel.types || []).map((type, index) => (
              <span className="tw bg-brand-altpurple rounded-full px-3 py-1 mr-4 mt-2" key={index}>
                <span className="text-xs font-extrabold text-brand-purple uppercase leading-none m-0">{type.displayName}</span>
              </span>
            ))}
            {(props.hotel.styles || []).map((type, index) => (
              <span className="tw bg-brand-altpurple rounded-full px-3 py-1 mr-4 mt-2" key={index}>
                <span className="text-xs font-extrabold text-brand-purple uppercase leading-none m-0">{type.displayName}</span>
              </span>
            ))}
          </div>
        </MarginContainer>
      </div>
    </>
  )
}
