import React, { useState } from 'react';
import { Container } from '../ui/containers';
import { get, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridContainer } from 'ui/containers';
import styled from 'styled-components';

const SustainableEngagementContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
`;

const IconPlaceholder = styled.div`
  height: 40px;
  width: 40px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const TextAlso = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.primary};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

const SustainableEngagementBlock = ({ hotel }) => {
  const { t } = useTranslation();
  const sustainableEngagements = get(hotel, 'sustainableEngagements', []);
  const sortedSustainableEngagement = orderBy(
    sustainableEngagements,['numeroLigne','displayName']
  );

  const [viewAll, setViewAll] = useState(false);

  return (
    <div>
      <Container noMargin noPadding>
        <div className="">
          <GridContainer variant="sustainableEngagements">
            {sortedSustainableEngagement.map((sustainableEngagement, index) => (index < 6 || viewAll) && (
              <SustainableEngagementContainer>
                {get(sustainableEngagement, 'icon.url', '') ? (
                  <Icon
                    src={get(sustainableEngagement, 'icon.url', '')}
                  />
                ) : (
                  <IconPlaceholder/>
                )}
                <Label>
                  {sustainableEngagement.displayName}
                </Label>
              </SustainableEngagementContainer>
            ))}
          </GridContainer>
        </div>

        {sortedSustainableEngagement.length > 1 && (
          <TextAlso onClick={() => setViewAll(!viewAll)}>
            {viewAll ? t('hotel.overview.sustainableEngagements.hide') : t('hotel.overview.sustainableEngagements.show')}...
          </TextAlso>
        )}
      </Container>
    </div>
  );
};

export default SustainableEngagementBlock;
