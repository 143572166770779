import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Container, GridContainer } from 'ui/containers';
import { Button as RawButton } from 'ui';
import infoBulle from 'assets/images/info-bulle.svg';
import ReactTooltip from 'react-tooltip';
import sanitizeHtml from 'sanitize-html';
import { groupBy, get, values, sortBy } from 'lodash';
import { useQueryParams } from 'hooks';
import { useAsync } from 'react-async';
import { getProfileFacilitiesInclude } from 'api/hotels';
import { includes } from 'lodash';
import AuthContext from 'contexts/auth';
import { useTranslation } from 'react-i18next';

const Button = styled(RawButton)`
  height: 50px;
`
const FacilityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const IconPlaceholder = styled.div`
  height: 20px;
  width: 20px;
`;

const FacilityLabel = styled.div`
  font-size: 16px;
  ${props =>
    props.isHighlight &&
    css`
      font-weight: bold;
      color: ${({ theme }) => theme.primary};
    `};
`;

const GroupTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const CustomTooltip = styled(ReactTooltip)`
  max-width: 300px;
  overflow-wrap: break-word;
  display: block;
  border-radius:50px !important;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
`;

const fetchUserFacilities = async ({ id, isAuthenticated }) => {
  if (!id || !isAuthenticated) {
    return;
  }
  return getProfileFacilitiesInclude({ id });
};

const ServicesHotel = ({ hasNoButton, hotelFacilities }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const queryParams = useQueryParams();
  const { profile } = queryParams;
  const {t} = useTranslation();

  const { data: { facilities: userFacilities } = [] } = useAsync({
    promiseFn: fetchUserFacilities,
    id: profile,
    isAuthenticated,
  });

  const [isShown, setIsShown] = useState(false);
  const groupes = sortBy(
    values(
      groupBy(hotelFacilities, ({ facility }) => get(facility, 'group.id')),
    ),
    [(group) => group[0].facility.group ?  group[0].facility.group.position : undefined],
  )

  for (let i = 0; i < groupes.length; i++) {
    groupes[i] = sortBy(
      groupes[i],
      ['facility.position'],
    )
  }

  const facilitiesTab = groupes;

  const userFacilitiesIds = (userFacilities || []).map(u => u.koobFacilityId);

  return (
    <Container noMargin noPadding style={{ marginBottom: '32px' }}>
      <GridContainer variant="services">
        {facilitiesTab.map((groupTab, groupIndex) => (
          <div key={groupIndex}>
            {groupTab.map(({ details, facility }, index) => (
              <div key={index}>
                {(hasNoButton || (groupIndex <= 2) || (groupIndex > 2 && isShown)) && (
                  <div>
                    {index === 0 && (
                      <GroupTitle>
                        {get(facility, 'group.displayName', '')}
                      </GroupTitle>
                    )}
                    <FacilityContainer>
                      {get(facility, 'icon.url', '') ? (
                        <Icon
                          src={get(facility, 'icon.url', '')}
                        />
                      ) : (
                        <IconPlaceholder/>
                      )}
                      <FacilityLabel
                        isHighlight={includes(
                          userFacilitiesIds,
                          Number(facility.id),
                        )}
                      >
                        {facility.displayName}
                      </FacilityLabel>
                      {details && (
                        <div>
                          <div
                            style={{ height: '26px' }}
                            data-tip={sanitizeHtml(details)}
                          >
                            <Icon src={infoBulle} />
                          </div>
                          <CustomTooltip
                            effect="solid"
                            delayHide={100}
                            delayShow={100}
                            type="light"
                            html={true}
                            border={false}
                            multiline={true}
                            place="bottom"
                          />
                        </div>
                      )}
                    </FacilityContainer>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </GridContainer>
      {!hasNoButton && facilitiesTab.length > 1 && (
        <Button variant="roundButton" onClick={() => setIsShown(!isShown)}>
          {' '}
          {isShown ? t('hotel.overview.services.hide') : t('hotel.overview.services.show')}
        </Button>
      )}
    </Container>
  );
};

export default ServicesHotel;
