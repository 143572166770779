import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, {css} from 'styled-components';

const Bar = styled.div`
  height:70px;
  display:flex;
  align-items:center;
  z-index:1000;
  width:100%;
  background: white;
  padding-left:100px;
  box-shadow: 0px 3px 26px #0000000A;
  ${props =>
    !props.visible &&
    css`
      display:none;
  `}
`

const Item = styled.a`
  margin-right:15px;
  color: grey;
  text-decoration:none;
  ${props =>
  props.selected &&
  css`
      color: black;
      font-weight:bold;
  `}
`
export default function Navbar({
  sectionSelected
}) {
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const [visible, setVisible] = useState('');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      setVisible((windowHeight > 50))
    }
  };
  return (
    <div className="hidden lg:block">
      <Bar visible={visible} className="fixed top-0 left-0">
        <Item href="#description" selected={sectionSelected==='description'}>{t('hotel.overview.description.headerTitle')}</Item>
        <Item href="#promise" selected={sectionSelected==='promise'}>{t('hotel.overview.promise.headerTitle')}</Item>
        <Item href="#engagements" selected={sectionSelected==='engagements'}>{t('hotel.overview.engagements.headerTitle')}</Item>
        <Item href="#services" selected={sectionSelected==='services'}>{t('hotel.overview.services.headerTitle')}</Item>
        <Item href="#labels" selected={sectionSelected==='labels'}>{t('hotel.overview.labels.headerTitle')}</Item>
        <Item href="#restaurants" selected={sectionSelected==='restaurants'}>{t('hotel.restaurant.title')}</Item>
        <Item href="#access" selected={sectionSelected==='access'}>{t('hotel.overview.access.headerTitle')}</Item>
        <Item href="#reviews" selected={sectionSelected==='reviews'}>{t('hotel.review.title')}</Item>
      </Bar>
    </div>
  )
}
