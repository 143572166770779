import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from 'components/Content';
import styled,{css} from 'styled-components'

const RestaurantImage = styled.div`
  display:none;
  ${props =>
  props.backgroundImage &&
    css`
      display: block;
      position:relative;
      width:100%;
      height:100%;
      background-image: ${props.backgroundImage};
      background-size: contain;
  `};
`

const RestaurantContainer = styled.div`
  height:300px;
`

const RestaurantContent = styled.div`
  height:300px;
  overflow-y:auto;
  padding:50px;
  ${props =>
  props.hasImage &&
  css`
      position:relative;
      height:100%;
      top:-100%;
      background: #00000099 0% 0% no-repeat padding-box;
      opacity: 1;
  `};
`

const RestaurantTitle = styled.div`
  font-weight:bold;
  font-size:20px;
  margin-bottom:20px;
`

const ContentWrapper = styled.div`
  font-size:16px !important;
  * {
    font-size:16px !important;
    line-height:16px;
    margin:0px;
  }
`
export default function RestaurantsSection({hotel}) {
  const { t } = useTranslation();

  return (
    <div className="tw py-16 px-8 sm:px-0 mx-8 xl:mx-0">
      <span className="text-2xl font-bold text-brand-black">{t('hotel.restaurant.newTitle')}</span>

      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {(hotel.restaurants || []).map((item, index) => (
          <RestaurantContainer
            className="bg-brand-grey rounded-3xl"
            key={index}
          >
            <RestaurantImage backgroundImage={item.attachments.length > 0 ? `url(${item.attachments[0]?.largeUrl})`: undefined}>

            </RestaurantImage>
            <RestaurantContent hasImage={item.attachments.length > 0}>
              <div>

              </div>

              <div>
                <div className={item.attachments.length > 0 && 'text-white'}>
                  <RestaurantTitle>{item.displayName}</RestaurantTitle>
                  <ContentWrapper>
                    {item.capacity > 1 &&
                    t('hotel.restaurant.roomNumber', {
                      count: item.capacity,
                    })}
                    <Content html={item.description}/>
                  </ContentWrapper>
                </div>
              </div>
            </RestaurantContent>
          </RestaurantContainer>

        ))}
      </div>
    </div>
  )
}
