import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import accent from 'assets/images/accent-2.png';

export default function OurPromiseSection(props) {
  const { t } = useTranslation();

  const promise = get(props.hotelApi, 'promise');

  return (
    <div className="tw pt-16 mx-8 xl:mx-0">
      <div className="bg-brand-grey p-8 rounded-3xl">
          <span className="text-2xl text-brand-black font-bold leading-none m-0">{t('hotel.overview.promise.title')}</span>
          <img className="h-2 w-auto" src={accent} alt="Accent"/>
        <p className="text-brand-text font-semibold mb-0">{promise && promise.length>0? promise:t('hotel.overview.promise.emptyField')}</p>
      </div>
    </div>
  )
}
