import React, { useState } from 'react';
import SustainabilityBlock from '../components/SustainabilityBlock';
import { Container } from '../ui/containers';
import { Button } from '../ui';
import sanitizeHtml from 'sanitize-html';
import Parser from 'html-react-parser';
import { get, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

const SustainabilityLevel = ({ hotel }) => {
  const { t } = useTranslation();
  const hotelSustainableClusters = get(hotel, 'hotelSustainableClusters', []);
  const sortedSustainableCluster = orderBy(
    hotelSustainableClusters,
    ({ sustainableCluster }) => sustainableCluster?.sort,
  );

  const defaultView = 5;
  const [viewAll, setViewAll] = useState(false);

  return (
    <div>
      <Container noMargin noPadding>
        <div className="">
          {sortedSustainableCluster.map(
            ({ descriptions, sustainableCluster }, index) => (index < defaultView || viewAll) && (
              <SustainabilityBlock
                key={index}
                title={sustainableCluster.displayName}
                text={Parser(
                  sanitizeHtml(descriptions, {
                    allowedAttributes: {
                      ...sanitizeHtml.defaults.allowedAttributes,
                      p: ['style'],
                      span: ['style'],
                    },
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                      'p',
                      'span',
                    ]),
                  }),
                )}
              />
            ),
          )}
        </div>

        {sortedSustainableCluster.length > 1 && (
          <Button variant="roundButton" onClick={() => setViewAll(!viewAll)} className="h-13">
            {' '}
            {viewAll ? t('hotel.overview.engagements.hide') : t('hotel.overview.engagements.show')}
          </Button>
        )}
      </Container>
    </div>
  );
};

export default SustainabilityLevel;
