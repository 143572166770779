import React from 'react';
import { ReviewCard } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getHotelComments } from 'api/hotels';
import { get } from 'lodash';

const ReviewsSection = ({ hotel, reviews, isList }) => {
  const { t } = useTranslation();
  const { data: dataComments } = useAsync({
    promiseFn: getHotelComments,
    hotelId: hotel.originalId,
  });

  const comments = get(dataComments, 'comments', []);

  return (
    <div className="tw py-32 px-8 sm:px-0 mx-8 xl:mx-0">
      <div>
        <span className="text-2xl font-bold text-brand-black">{t('hotel.reviews.title')}</span>
        <div>
          {comments.length !== 0 ? (
            comments.map(comment => <ReviewCard {...comment} />)
          ) : (
            <p className="text-gray-400 my-10">{t('hotel.reviews.emptyField')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewsSection;
