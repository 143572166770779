import React, { useMemo,useState,useEffect } from 'react';
import { useLocation } from 'react-use';
import { get, round } from 'lodash';
import styled,{ css } from 'styled-components';
import { parseSearch,formatSearch } from '../../../utils';
import {VIEWPORT_SIZES} from '../../../ui';
import format from "date-fns/format";
import { navigate } from '@reach/router';
import { useGTM } from '@tymate/react-gtm';
import { Formik,Form  } from 'formik';
import { DateField } from '../../SingleDateField';
import FieldWithTitle from '../../Search/FieldWithTitle';
import { useTranslation } from 'react-i18next';
import SearchAges from '../../SearchAges';
import { Button as RawButton } from 'ui';
import { InView } from 'react-intersection-observer';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { ERRORS } from 'utils';

const FieldContainer = styled.div`
  display:flex;
  padding:5px;
  width:100%;
  margin-top:10px;
  margin-bottom:15px;
`

const PriceContainer = styled.span`
  font-size:15px;
  font-weight:bold;
  ${props =>
    props.mobile &&
    css`  
      flex:1;
  `}
`
const ContainerDateField = styled.div`
  margin-top: 8px;
  margin-bottom: 14px !important;
  height: 90%;
  width:100%;
  border: 0.8px solid rgb(194,194,194);
  padding-top: 8px;
  padding: 15px 5px;
  ${props =>
    props.left &&
      css`
      padding-left: 8px;
      border-radius: 10px 0 0 10px;
  `}
  ${props =>
    props.right &&
      css`
      padding-left: 16px;
      border-radius: 0px 10px 10px 0px;
  `}
 > .SingleDateField .DateInput_input.DateInput_input {
    font-size: 14.5px !important
  }
`

const ContainerAgeField = styled.div`
  margin-top: 8px;
  height: 100%;
  width:100%;
  padding: 15px 5px;
  padding-left: 8px;
  border: 0.8px solid rgb(194,194,194);
  border-radius: 10px;
`

const WidgetContainer = styled.div`
  position:absolute;
  left: calc(100vw - 400px);
  top:-200px;
  width: 275px;
  ${props =>
    props.sticky &&
    css`
        position:fixed;
        top:100px;
    `}
  ${props =>
  props.inline &&
  css`
    position:initial;
    top:unset;
    left: unset;
    margin-top:40px;
  `}
  display:flex;
  padding:25px;
  border-radius:25px;
  display:flex;
  flex-direction:column;
  align-items:center;
  background:white;

  box-shadow: 0px 3px 26px #0000000A;
`

const WidgetMobileContainer = styled.div`
  position:fixed;
  left: 25vw;
  bottom:30px;
  width: 50vw;
  @media screen and (max-width:450px) {
    width: 70vw;
    ${props =>
      props.buttonFull &&
            css`
        width: 50vw;
    `}
  }
  height:60px;
  padding-left:25px;
  padding-right:25px;
  border-radius:28px;
  z-index:1000;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  background:white;
  align-items:center;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  ${props =>
  props.buttonFull &&
  css`
     height:50px;
     padding-left:0px;
     padding-right:0px;
     box-shadow: unset;
  `}
`

const Price = styled.span`
  font-weight:bold;
  color:#67CB8B;
  font-size: 25px;
`

const Button = styled(RawButton)`
  height: 50px;
`;

const ButtonContainer = styled.div`
  margin-top:20px;
  flex: content 0 0;
  ${props =>
  props.mobile &&
    css`
      margin-top:0px;
      flex:1;
    `}
`;

const ButtonWrapper = styled.div`
  color: #fff;
  font-weight: bold;
  svg {
    display: none;
    margin: 0;
  }

  text-transform: none;

  p {
    display: block;
  }
`;

export default function Prices({ hotel, setShowStickyWidget, sticky }) {
  const location = useLocation();
  const search = useMemo(
    () => parseSearch((get(location, 'search') || '').substring(1)),
    [location],
  );

  let defaultValue = 1200;
  if (typeof window !== 'undefined') {
    defaultValue = window.innerWidth;
  }

  const [width, setWidth] = useState(defaultValue);
  const [searchModalOpened, setSearchModalOpened] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width < VIEWPORT_SIZES.desktop;
  const { pushDataLayer } = useGTM();
  const {t}  = useTranslation();

  const handleSubmit = values => {
    const bookingUrl = `/booking/${hotel.originalId}?${formatSearch({
      startDate: format(values.startDate, 'YYYY-MM-DD'),
      endDate: format(values.endDate, 'YYYY-MM-DD'),
      ageCategories: {
        adults: values.ageCategories.adults,
        children:values.ageCategories.children,
        infants: values.ageCategories.infants,
      }
    })}`;

    pushDataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
          },
        },
      },
    });

    navigate(bookingUrl);
  };

  const adults = search && search.ageCategories && search.ageCategories.adults? Number(search.ageCategories.adults): 2;
  const children = search && search.ageCategories && search.ageCategories.children? Number(search.ageCategories.children): 0;
  const infants = search && search.ageCategories && search.ageCategories.infants? Number(search.ageCategories.infants): 0;

  const form = (values, isValid, inline)=>(
    <Form>
      <WidgetContainer sticky={sticky} inline={inline}>
        {search.minPrice &&
          <PriceContainer>
            <Price>{round(search.minPrice, 0)}€</Price>
            {t('hotel.overview.pricingWidget.price')}
          </PriceContainer>
        }
        <FieldContainer>
          <FieldWithTitle
            title={t('newSearchBar.arrival.title')}
            showBorder={false}
            flex={'1 1 100px'}
            flexMobile={'1 1 100px'}
            WidgetTitle = "WidgetTitleLeft"
          >
            <ContainerDateField left>
              <DateField
                variant="widget"
                id="start"
                name="startDate"
                datePlaceholder={t('newSearchBar.arrival.text')}
                otherDate={values.endDate}
                isStart={true}
                full={true}
                allowFuture
                clearDateDisabled
                actionOnfocus={function() {
                  return null
                }}

              />
            </ContainerDateField>
          </FieldWithTitle>
          <FieldWithTitle
            title={t('newSearchBar.leave.title')}
            showBorder={false}
            flex={'1 1 100px'}
            flexMobile={'1 1 100px'}
            WidgetTitle = "WidgetTitleRight"
          >
          <ContainerDateField  right>
            <DateField
              id="end"
              variant="home"
              name="endDate"
              datePlaceholder={t('newSearchBar.leave.text')}
              otherDate={values.startDate}
              isStart={false}
              full={true}
              allowFuture
              clearDateDisabled
              actionOnfocus={function() {
                return null
              }}
            />
          </ContainerDateField>
          </FieldWithTitle>
        </FieldContainer>
        <FieldContainer>
          <FieldWithTitle
            title={t('newSearchBar.travellers.title')}
            flex={'1 0 200px'}
            flexMobile={'1 0 200px'}
            WidgetTitle = "WidgetTitleLeft"
          >
            <ContainerAgeField>
              <SearchAges
                name="ageCategories"
                variant="homeSearch"
                placeholder={t('newSearchBar.travellers.text')}
                simplified
                widgetAge
              />
            </ContainerAgeField>
          </FieldWithTitle>
        </FieldContainer>
        <ButtonContainer>
          <Button
            style={{width: '100%'}}
            variant="homeSearch"
            type="submit"
            disabled={!isValid}
          >
            <ButtonWrapper>
              <p style={{ color: 'black' }}>
                {t('hotel.overview.pricingWidget.book')}
              </p>
            </ButtonWrapper>
          </Button>
        </ButtonContainer>
      </WidgetContainer>
    </Form>
  )

  return (
    <InView as="div" threshold={0} onChange={(inView) => {
      setShowStickyWidget(!inView);
    }}>
      <Formik
        enableReinitialize
        initialValues={
          search
            ? {
              ...search,
              startDate:search.startDate,
              endDate:search.endDate,
              ageCategories: {
                adults: Number(adults),
                children: Number(children),
                infants: Number(infants),
              }
            }
            : {}
        }
        validationSchema={Yup.object().shape({
          startDate: Yup.string().required(ERRORS.REQUIRED),
          endDate: Yup.string().required(ERRORS.REQUIRED)
        })}
        onSubmit={handleSubmit}
      >
        {({ values, isValid }) => !isMobile || (!isMobile && sticky) ? form(values,isValid) :
          <Form>
            <WidgetMobileContainer buttonFull={!search.minPrice}>
              { search.minPrice &&
              <PriceContainer mobile>
                <Price>{
                  round(search.minPrice, 0)
                }€</Price>
                {t('hotel.overview.pricingWidget.price')}
              </PriceContainer>
              }
              <ButtonContainer mobile buttonFull={!search.minPrice}>
                <Button
                  style={{width: '100%'}}
                  variant="homeSearch"
                  type={search.minPrice ? 'submit' : 'button'}
                  onClick={()=>setSearchModalOpened(true)}
                  disabled={search.minPrice && !isValid}
                >
                  <ButtonWrapper>
                    <p style={{ color: 'black' }}>
                      {t('hotel.overview.pricingWidget.book')}
                    </p>
                  </ButtonWrapper>
                </Button>
              </ButtonContainer>
            </WidgetMobileContainer>
            <Modal isOpen={searchModalOpened} onRequestClose={() => setSearchModalOpened(false)} variant={'customStyles'} isSearchBar>
              {form(values, isValid,true)}
            </Modal>
          </Form>
        }
      </Formik>
    </InView>
  )
}
