import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import SustainabilityLevel from '../../components/SustainabilityLevel';
import SustainabilityEngagements from '../../components/SustainabilityEngagements';
import infoBulle from 'assets/images/info-bulle.svg';

const CustomTooltip = styled(ReactTooltip)`
  max-width: 300px;
  overflow-wrap: break-word;
  display: block;
  border-radius:50px !important;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, .5);
  text-align: center;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const SustainableTitle = styled.div`
  display: flex;
  align-items: center;
`;

export default function EngagementsSection({hotel}) {
  const { t } = useTranslation();

  return (
    <div className="tw py-16 px-8 sm:px-0 mx-8 xl:mx-0">
      <SustainableTitle>
        <h3 className="text-2xl font-bold text-brand-black">{t('hotel.sustainable.title')}</h3>
        <div
          style={{ height: '26px', display: 'flex', alignItems: 'end', marginLeft: '20px' }}
          data-tip={t('hotel.sustainable.info')}
          data-for="tooltip"
        >
          <Icon src={infoBulle} />
        </div>
        <CustomTooltip
            id="tooltip"
            effect="solid"
            delayHide={100}
            delayShow={100}
            type="light"
            html={true}
            border={false}
            multiline={true}
            place="bottom"
        />
      </SustainableTitle>

      <SustainabilityEngagements hotel={hotel}/>
      <SustainabilityLevel hotel={hotel}/>
    </div>
  )
}
