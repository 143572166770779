import React from 'react';
import { MapContainer, MyMap } from '../MyMap';
import Content from 'components/Content';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button as ButtonRaw } from 'ui';
import { useCopyToClipboard } from 'react-use';
import { MdOutlineContentCopy } from 'react-icons/md';
import { theme } from 'ui/colors';

const HotelAccessAdressContainer = styled.div`
  height:100px;
  display:flex;
  padding: 10px 15px;
  flex-direction:row;
  align-items:center;
  flex-wrap:wrap;
  margin-bottom:20px;
  justify-content:center;
  > * {
    margin-left:10px;
  }
`;

const HotelAccessAdress = styled.div`
  margin-bottom:10px;
`;

const CopyIcon = styled(MdOutlineContentCopy)`
  margin-left:10px;
  margin-right:0px !important;
`;

const HotelAccessAdressCopyButton = styled(ButtonRaw)`
  width:250px;
  margin-bottom:10px;
`;
const AccessTitle = styled.div`
  font-weight:bold;
  font-size:16px;
`;

const ContentWrapper = styled.div`
  * {
    font-size:16px !important;
  }
`

const Description = styled.div`
  margin-bottom:60px;
`

export default function AccessSection({ hotel }) {
  const { t } = useTranslation();
  const [state, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="tw py-32 px-8 sm:px-0 mx-8 xl:mx-0">
      <span className="text-2xl font-bold text-brand-black">{t('hotel.access.title')}</span>

      {hotel.lat && hotel.lon && (
        <div className="mt-8 h-96 rounded-t-3xl overflow-hidden tw-maps-container">
          <MapContainer>
            <MyMap
              isMarkerShown
              address={hotel?.address}
              lat={hotel.lat}
              lng={hotel.lon}
            />
          </MapContainer>
        </div>
      )}

      <HotelAccessAdressContainer className={['bg-brand-green flex justify-between', hotel.lat && hotel.lon ? 'rounded-b-3xl' : 'rounded-3xl'].join(' ')}>
        <HotelAccessAdress className="font-semibold text-white leading-none">{hotel.address}</HotelAccessAdress>
        <HotelAccessAdressCopyButton variant={'hotelAccess'} onClick={()=> copyToClipboard(hotel.address)}>
          {state.value ? t('hotel.access.copyAddressDone') : t('hotel.access.copyAddress')}
          <CopyIcon size={15} color={theme.secondary} />
        </HotelAccessAdressCopyButton>
      </HotelAccessAdressContainer>

      {(hotel.hotelDescriptions || []).map((item, index) => (
        <>
          {item.description.displayName !== 'N° étages ' &&
          item.description.displayName !== 'Description' &&
          item.content && (
            <Description key={index}>
              <AccessTitle>{item.description.displayName}</AccessTitle>
              <ContentWrapper>
                <Content html={item.content}/>
              </ContentWrapper>
            </Description>
          )}
        </>
      ))}
    </div>
  )
}
