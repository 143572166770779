import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import './photo-carousel.css';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { MdZoomOutMap } from 'react-icons/all';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import imgLoading from 'assets/images/loaderTransparent.png';

const settings = {
  className: 'slider variable-width',
  infinite: true,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
};

const MarginContainer = styled.div`
  margin-left: 8vw;
`;

export default function PhotoCarrousel({ images }) {
  const slider = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const openModalAtIndex = index => {
    setActiveItemIndex(index ?? 0);
    setModalIsOpen(true);
  };

  return (
    <>
      <div className="relative pt-8 sm:pt-0 w-full">
        <Slider ref={slider} {...settings}>
          {images.map((elem, index) => (
            <>
              <div className="px-1 sm:px-5">
                <div className="relative">
                  <LazyLoadImage
                    alt="hotel carousel"
                    className="carousel-img w-auto rounded-3xl"
                    src={elem.src}
                    threshold={100}
                    afterLoad= {() => setIsImageLoaded(false)}
                    placeholderSrc={imgLoading}
                  />
                {!isImageLoaded ?   
                  <button
                    className="absolute right-5 bottom-5 bg-white p-2 rounded-xl"
                    onClick={() => openModalAtIndex(index)}
                  >
                    <MdZoomOutMap size={20} />
                  </button> :
                '' }
                </div>
              </div>
            </>
          ))}
        </Slider>
       {!isImageLoaded ?  
        <MarginContainer>
          <div className="absolute" style={{ bottom: '-22px' }}>
            <div className="flex gap-3 w-32">
              <button
                onClick={() => slider.current.slickPrev()}
                className="bg-brand-orange p-3 rounded-2xl"
              >
                <img
                  className="h-5 w-5"
                  src={require('../../../assets/svg/left_arrow.svg')}
                  alt=""
                />
              </button>
              <button
                onClick={() => slider.current.slickNext()}
                className="bg-brand-orange p-3 rounded-2xl"
              >
                <img
                  className="h-5 w-5 transform rotate-180"
                  src={require('../../../assets/svg/left_arrow.svg')}
                  alt=""
                />
              </button>
            </div>
          </div>
        </MarginContainer> : '' }
      </div>

      {!isImageLoaded ?  
      <ModalGateway>
        {modalIsOpen && (
          <Modal onClose={() => setModalIsOpen(false)}>
            <Carousel views={images} currentIndex={activeItemIndex} />
          </Modal>
        )}
      </ModalGateway>: '' }
    </>
  );
}
